import styles from "./Header.module.css";

export const navItems = [
    {
        name: "About",
        ref: "#about",
        className: styles.navItem
    },

    {
        name: "Work",
        ref: "#work",
        className: styles.navItem
    },

    // {
    //     name: "Projects",
    //     ref: "#projects",
    //     className: styles.navItem
    // },
    {
        name: "Education",
        ref: "#education",
        className: styles.navItem
    },

    {
        name: "Resume",
        ref: "https://docs.google.com/document/d/10GuuYA2OouwNIfRBhwr1yo_DNE5LOa9c4MSrsbTUA00/edit?usp=sharing",
        className: "button-primary"
    }
];